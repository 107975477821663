<template>
    <div class="right_main">
        <!-- 页面标题  按钮部分 -->
        <div class="top_btn">
            <!-- 页面标题部分 -->
            <div class="page_name">
                <div class="sig">
                    <i class="el-icon-arrow-left" @click="handle_goback"></i>
                </div>
                <div class="sig_name">{{tip}}</div>
            </div>
            <div class="btn_cl"></div>
        </div>
        <!-- 输入框部分 -->
        <div class="from_main">
            <el-form label-position="left" label-width="118px">
                <el-form-item label="用户名">
                    <el-input v-model="user_name" placeholder="请输入用户名" clearable></el-input>
                    <div class="shugan"></div>
                </el-form-item>
                <el-form-item label="公司名称" v-if="partShow">
                    <el-select v-model="partner_id" placeholder="请选择公司" clearable>
                        <el-option v-for="item in partner_arr" :key="item.uid" :label="item.name" :value="item.uid">
                        </el-option>
                    </el-select>
                    <div class="shugan"></div>
                </el-form-item>
                <el-form-item label="登录账号">
                    <el-input v-model="login_num" placeholder="请输入登录账号" clearable></el-input>
                    <div class="shugan"></div>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="phone" placeholder="请输入手机号" clearable></el-input>
                    <div class="shugan"></div>
                </el-form-item>
                <el-form-item label="权限">
                    <el-select v-model="auth_id" placeholder="请选择权限" clearable>
                        <el-option v-for="item in auth_arr" :key="item.uid" :label="item.user_name" :value="item.uid">
                        </el-option>
                    </el-select>
                    <div class="shugan"></div>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input v-model="pwd" type="password" placeholder="请输入密码(6-20位)" clearable></el-input>
                    <div class="shugan"></div>
                </el-form-item>
                <el-form-item label="确认密码">
                    <el-input v-model="password" type="password" placeholder="请再次输入密码(6-20位)" clearable></el-input>
                    <div class="shugan"></div>
                </el-form-item>
                <el-form-item label="状态">
                    <el-radio v-model="radio" label="1" @change="stateFn">正常</el-radio>
                    <el-radio v-model="radio" label="2" @change="stateFn">禁用</el-radio>
                </el-form-item>
            </el-form>
        </div>
        <!-- 提交重置按钮 -->
        <div class="btn_main">
            <el-button class="btn_rest" @click="ResterFn">重置</el-button>
            <el-button class="btn_submit" @click="SubmitFn">{{btn_name}}</el-button>
        </div>
    </div>
</template>

<script>
import { showPartRoleApi, addPartRoleApi, editPartRoleApi, showAdminApi, addAdminApi, editAdminApi } from '@/api'
export default {
    data () {
        return {
            // 页面标题
            tip: '',
            // 按钮名字
            btn_name: '',
            // 每条数据id
            id: '',
            // 用户名
            user_name: '',
            // 公司名称显示隐藏
            partShow: false,
            // 公司名称
            partner_id: '',
            partner_arr: [],
            // 登陆账号
            login_num: '',
            // 手机号
            phone: '',
            // 权限
            auth_id: '',
            auth_arr: [],
            // 密码
            pwd: '',
            // 确认密码
            password: '',
            // 状态
            radio: '1',
            auth_state: 1,
        }
    },
    mounted () {
        this.tip = this.$route.query.tip;
        this.id = this.$route.query.id;
        if (this.tip == '合作方添加' || this.tip == 'admin添加') {
            this.btn_name = '确认添加';
            if (this.tip == '合作方添加') {
                this.add_partner();
                this.partShow = true;
            } else {
                this.add_admin();
                this.partShow = false;
            };
        } else {
            this.btn_name = '确认修改';
            if (this.tip == '合作方编辑') {
                this.edit_partner();
                this.partShow = true;
            } else {
                this.edit_admin();
                this.partShow = false;
            };
        }
    },
    methods: {
        // 添加初始化数据  合作方
        add_partner () {
            showPartRoleApi({
                type: 1
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.partner_arr = res.partner;  //公司
                    this.auth_arr = res.auth;  // 权限
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 添加初始化数据  admin
        add_admin () {
            showAdminApi({
                type: 1
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.auth_arr = res.auth;  // 权限
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 编辑初始化数据  合作方
        edit_partner () {
            showPartRoleApi({
                type: 2,
                id: this.id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.partner_arr = res.partner;  //公司
                    this.auth_arr = res.auth;  // 权限
                    this.user_name = res.data.nickname;    //用户名
                    this.partner_id = res.data.partner_uid;  //合作方id
                    this.login_num = res.data.username;     //账号
                    this.phone = res.data.phone;     //手机号
                    this.auth_id = res.data.auth_id;    //群组id
                    this.pwd = res.data.rel_pwd;    //密码
                    this.password = res.data.rel_pwd;    //确认密码
                    this.auth_state = res.data.status;     //状态
                    if (this.auth_state == 1) {
                        this.radio = '1';
                    } else {
                        this.radio = '2';
                    };
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 编辑初始化数据  admin
        edit_admin () {
            showAdminApi({
                type: 2,
                id: this.id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.auth_arr = res.auth;  // 权限
                    this.user_name = res.data.nickname;    //用户名
                    this.login_num = res.data.username;     //账号
                    this.phone = res.data.phone;     //手机号
                    this.auth_id = res.data.auth_id;    //群组id
                    this.pwd = res.data.rel_pwd;    //密码
                    this.password = res.data.rel_pwd;    //确认密码
                    this.auth_state = res.data.status;     //状态
                    if (this.auth_state == 1) {
                        this.radio = '1';
                    } else {
                        this.radio = '2';
                    };
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 状态单选
        stateFn () {
            if (this.radio == '1') {
                this.auth_state = 1;
            } else {
                this.auth_state = 2;
            }
        },
        // 点击重置按钮
        ResterFn () {
            this.user_name = '';      //用户名
            this.partner_id = '';     //公司名
            this.login_num = '';    //登陆账号
            this.phone = '';  //手机号
            this.auth_id = '';      //权限
            this.pwd = '';   //密码
            this.password = '';   //确认密码
            this.radio = '1';     //状态
        },
        // 点击返回按钮返回
        handle_goback () {
            this.$router.push({ path: '/authuser' })
        },
        // 点击提交按钮
        SubmitFn () {
            if (this.tip == '合作方添加') {
                addPartRoleApi({
                    username: this.login_num,  //账号
                    phone: this.phone,    //菜单名称
                    auth_id: this.auth_id,  // 权限
                    password: this.pwd,   //密码
                    password_confirmation: this.password,   //确认密码
                    status: this.auth_state,  //状态
                    nickname: this.user_name,  //用户名
                    partner_uid: this.partner_id,   //合作方
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('添加成功');
                        this.$router.push({ path: '/authuser' })
                        this.ResterFn();
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else if (this.tip == '合作方编辑') {
                editPartRoleApi({
                    id: this.id,
                    username: this.login_num,  //账号
                    phone: this.phone,    //菜单名称
                    auth_id: this.auth_id,  // 权限
                    password: this.pwd,   //密码
                    password_confirmation: this.password,   //确认密码
                    status: this.auth_state,  //状态
                    nickname: this.user_name,  //用户名
                    partner_uid: this.partner_id,   //合作方
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('编辑成功');
                        this.$router.push({ path: '/authuser' })
                        this.ResterFn();
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else if (this.tip == 'admin添加') {
                addAdminApi({
                    username: this.login_num,  //账号
                    phone: this.phone,    //菜单名称
                    auth_id: this.auth_id,  // 权限
                    password: this.pwd,   //密码
                    password_confirmation: this.password,   //确认密码
                    status: this.auth_state,  //状态
                    nickname: this.user_name,  //用户名
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('添加成功');
                        this.$router.push({ path: '/authuser' })
                        this.ResterFn();
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else if (this.tip == 'admin编辑') {
                editAdminApi({
                    id: this.id,
                    username: this.login_num,  //账号
                    phone: this.phone,    //菜单名称
                    auth_id: this.auth_id,  // 权限
                    password: this.pwd,   //密码
                    password_confirmation: this.password,   //确认密码
                    status: this.auth_state,  //状态
                    nickname: this.user_name,  //用户名
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('编辑成功');
                        this.$router.push({ path: '/authuser' })
                        this.ResterFn();
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.right_main {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: #ffffff;
    //  页面标题  按钮部分
    .top_btn {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        padding: 24px;
        border-bottom: 1px solid #e8e9f1;
        // 页面标题部分
        .page_name {
            display: flex;
            align-items: center;
            .sig {
                margin-right: 6px;
                i {
                    font-size: 24px;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
            .sig_name {
                font-size: 16px;
                font-weight: 600;
                color: #000000;
            }
        }
        .btn_cl {
            width: 10px;
        }
    }
    // 输入框部分
    .from_main {
        padding: 24px;
        .el-form {
            text-align: left;
            .el-form-item {
                width: 370px;
                position: relative;
                border: 1px solid #d9d9d9;
                border-radius: 6px;
                /deep/.el-form-item__label {
                    text-align: center;
                    font-size: 14px;
                    font-weight: 500;
                    color: #333333;
                }
                .el-input {
                    border: none;
                    width: 252px;
                    height: 44px;
                }
                /deep/.el-input__inner {
                    border: none;
                    width: 252px;
                    height: 44px;
                }
                .shugan {
                    background: #d3d3d6;
                    width: 2px;
                    height: 16px;
                    position: absolute;
                    right: 258px;
                    top: 12px;
                }
            }
        }
    }
    // 提交 重置按钮部分
    .btn_main {
        margin-top: 100px;
        text-align: left;
        padding: 0 20px 32px;
        .el-button {
            width: 160px;
            height: 40px;
            border-radius: 6px;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
        }
        .btn_submit {
            border: none;
            background: #6a8be8;
            margin-left: 8px;
            color: #ffffff;
        }
        .btn_rest {
            border: 1px solid #d9d9d9;
            color: #20252b;
            background: #ffffff;
        }
    }
}
</style>